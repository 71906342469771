import React from "react";

// Customizable Area Start
import { Input } from '@builder/component-library';

import { View, StyleSheet } from "react-native";
import { FormLabel ,
  Grid,
  Box,
  Typography,
  Button,
  Paper
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import {Logo} from './assets'
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  

   styleOfUi:any = {
    accountButton: {
      padding: '5px 15px',
      borderRadius: '20px',
      border: '1px solid',
      borderColor: 'transparent',
      marginHorizontal: '10px',
    },
    accountUnselected: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      cursor:'pointer'
    },
    accountText: {
      fontSize: '16px',
    },
    accountSelectedText: {
      color: 'green',
      fontWeight: 'bold',
    },
    accountUnselectedText: {
      color: 'gray',
    },
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
        <Grid container component="main" sx={{ height: '100vh' }}>
           <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 1,
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'right',
            }}
          >
            <Box sx={{ mt: 1 }}>
              <Grid container sx={{ px: '43px', display: 'flex', flexDirection: 'column' }}>
              <View style={styles.formWrapper}>

              <img src={Logo.default} style={{height:'auto',width:'42px', marginBottom:'78px'}}/>

                  <>
                  <div style={{ paddingBottom:'20px'}}>
                              <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: "30px", fontWeight: 400, lineHeight: "40px" }} >
                                welcome
                              </Typography>
                              <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: "18px", fontWeight: 700, lineHeight: "26px" }}>
                                Signin Now
                              </Typography>
                              </div>
                  </>

              <View    style={{marginBottom:'20px'}}>
              <FormLabel sx={{fontFamily: "Inter", fontSize: "14px", fontWeight: 700, lineHeight: "22px", color: "#334155", marginBottom: "4px"}}>Email</FormLabel>
                <Input
                  data-test-id="txtInputEmail"
                  placeholder={"Enter mail id"}
                  value={this.state.email}
                  onChangeText={(text) => this.setEmail(text)}
                />
              </View>
              <View style={{marginBottom:'20px'}}>
              <FormLabel style={{fontSize: "14px", lineHeight: "22px", fontWeight: 700, fontFamily: "Inter", color: "#334155", marginBottom: "4px" }}>Password</FormLabel>
                <Input
                  data-test-id="txtInputPassword"
                  secureTextEntry={this.state.enablePasswordField}
                  placeholder={"Enter password"}
                  value={this.state.password}
                  onChangeText={(text) => this.setPassword(text)}
                  rightIcon={
                    <Button
                      data-test-id="btnTogglePassword"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      style={{color:'#94A3B8',...styles.removeBackground}}
                      
                    >{this.state.enablePasswordField ? (
                     <VisibilityOff/>
                    ) : (
                      <Visibility/>  
                    )}</Button>
                  }
                  
                />
                 <span style={{color:'rgb(211, 47, 47)', marginTop: "5px", fontFamily: "Inter", fontSize: "0.75rem",}}>{this.state.labelOr}</span> 
              </View>
              <View style={{ display: "flex", alignItems: "flex-end" , paddingLeft:'20px', marginBottom:'20px'}}>
              
                  <FormLabel  
                  data-test-id={"btnForgotPassword"}
                  onClick={() => this.goToForgotPassword()}
                  sx={{'& .MuiFormLabel-root':{
                    fontSize:'14px',
                    alignSelf:'end'
                  }}}
                  style={{
                    cursor: "pointer",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#1E293B"
                  }}
                  >
                    Forgot Password?</FormLabel>
                
              </View>
              <View
              >
                <Button
                  data-test-id={"btnEmailLogIn"}
                  onClick={() => this.doEmailLogInApi()}
                  sx={{
                    backgroundColor: '#067B3E',
                    '& .MuiButtonBase-root': {
                      backgroundColor: '#067B3E',
                      color:'#ffff'
                    },
                    '&:hover': {
                      backgroundColor: '#067B3E',
                    },}
                  }
                  
                  style={{color:'#ffff', textTransform: "none", fontFamily: "Inter", fontSize: "16px", lineHeight: "24px", fontWeight: 700}}
                  
                >Continue</Button>
                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", gap: '5px', marginTop: "21px"}}>
                <Typography sx={{fontFamily:'Inter',fontSize:'14px', fontWeight:'400'}}>Don’t have account? </Typography>
                <span style={{color:'#067B3E', fontWeight:700, fontSize: "14px", fontFamily: "Inter", cursor: "pointer"}}  data-test-id ='upbtn'onClick={  ()=>this.routetopage('EmailAccountRegistration')}>Signup now</span>
                </Box>
              </View>
              
              <View
                style={styles.buttonsWrapper}
              > 
              </View>
              </View>
              </Grid>
            </Box>
          </Box>
        </Grid>
     
     <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url("https://www.nanalyze.com/app/uploads/2020/01/Cannabis-Indoor-Farming-Stock-Teaser-1024x682.jpg")',
            backgroundRepeat: 'no-repeat', 
            backgroundSize: 'cover',    
            backgroundPosition: 'center', 
            display: 'block'
          }}
        />
          
      </Grid>
      
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});

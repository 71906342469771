import React from "react";
import {
  Drawer,
  Button,
  TextField,
  Grid,
  Avatar,
  Typography,
  IconButton
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import { Account } from "../Settings2Controller";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';

// Validation Schema
const validationSchemaProfile = Yup.object().shape({
  contact_name: Yup.string().trim().matches(/^[A-Za-z\s]+$/, 'Name can only contain alphabets').required('Name is required'),
  email: Yup.string().trim().email('Invalid email').required('email is required'),
  phone_number: Yup.string()
  .matches(/^\d{10}$/, 'Phone Number must be exactly 10 digits')
  .required('Phone Number is required'),
  business_description: Yup.string().nullable(),

});

// Form Component
const EditProfileDrawer = ({ open, onClose ,user, submitFn}:{
    open: boolean;
    onClose: ()=>void;
    user : Account| any;
    submitFn:(e:any)=>void;
    
}) => {
    
    const formStyle = {
        inputStyle: {
          marginBottom: 10, width: "100%",
          fontSize: '14px',
        "& .Mui-error": {
            marginLeft : '0px', paddingLeft: "0px", color:'pink'
          },
          "& .MuiFormHelperText-root":{
  
            marginLeft : '0px', paddingLeft: "0px", color:'pink'
          }
        },
        btnstyle: {
          BackgroundColor: '#067B3E',
          width: '100%'
        },
        formlable: {
          fontFamily: 'Inter,sans-serif',
          fontSize: '14px',
          fontWeight: 700,
          marginBottom: '15px',
          lineHeight: '22px',
        }
        ,
  
      }
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div style={{ width: 400, padding: 16 }}>
        <Formik
          initialValues={{
            contact_name: user.attributes.contact_name||'',
            email: user.attributes.email||'',
            phone_number: user.attributes.phone_number,
            business_description: user.attributes.business_description,
            photo: user.attributes.photo_url||null,
          }}
          validationSchema={validationSchemaProfile}
          onSubmit={(values) => {
            console.log('state', values)
            submitFn(values)
          }}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6"
                 sx={{
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '32px',
                    letterSpacing: '-0.5%',
                  }}
                >Edit Profile</Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>

              {/* Image Upload */}
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    // @ts-ignore
                    const file = event.currentTarget.files[0];
                    setFieldValue('photo', file);
                  }}
                  hidden
                  id="profile-pic-upload"
                />
                <label htmlFor="profile-pic-upload">
                  <IconButton component="span">
                  <Avatar  src={values?.photo instanceof Blob ? URL.createObjectURL(values.photo) : `${values.photo}`} sx={{ width: 132, height: 132, }} />
                  <Typography sx={{position: "absolute", top: "50%",left: "50%", color: "white", transform: "translate(-50%, -50%)", backgroundColor: "rgba(0,0,0,0.5)",fontWeight: "700", fontSize: "14px", borderRadius: "50%",padding: "40px 28px", display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}><PhotoCameraOutlinedIcon/><span style={{whiteSpace: "nowrap"}}>Edit Picture</span></Typography>
                  </IconButton>
                </label>
              </div>

              {/* Form Fields */}
              <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item xs={12}>
                <label style={formStyle.formlable}>Name</label>
              <TextField
                style={formStyle.inputStyle}
                    placeholder="Business name"
                    name="contact_name"
                    size="small"
                    variant="outlined"
                    value={values.contact_name}
                    onChange={handleChange}
                    error={touched.contact_name && Boolean(errors.contact_name)}
                      // @ts-ignore
                    helperText={touched.contact_name && errors.contact_name}
                  />
                </Grid>

                
                <Grid item xs={12}>
                <label style={formStyle.formlable}>Email</label>
              <TextField
                style={formStyle.inputStyle}
                    placeholder="Business email"
                    name="email"
                    size="small"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                      // @ts-ignore
                    helperText={touched.email && errors.email||""}
                  />
                </Grid>

                <Grid item xs={12}>
                <label style={formStyle.formlable}>Phone</label>
              <TextField
                style={formStyle.inputStyle}
                    placeholder="330-555-8831"
                    name="phone_number"
                    size="small"
                    variant="outlined"
                    value={values.phone_number}
                    onChange={handleChange}
                    error={touched.phone_number && Boolean(errors.phone_number)}
                      // @ts-ignore
                    helperText={touched.phone_number && errors.phone_number||""}
                  />
                </Grid>

                <Grid item xs={12}>
                <label style={formStyle.formlable}>Business Description</label>
              <TextField
                style={formStyle.inputStyle}
                    placeholder="Enter value"
                    name="business_description"
                    size="small"
                    variant="outlined"
                    value={values.business_description === "null" ? "" : values.business_description}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                // style={{ marginTop: 16 }}
                sx={{
                    ...formStyle.btnstyle, '& .MuiButtonBase-root': {
                      backgroundColor: '#067B3E',
                    },
                  }} 
                  style={{ backgroundColor: '#067B3E',marginTop: 5}}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={onClose}
                variant="contained"
                color="secondary"
                size="small"
                // style={{ marginTop: 16 }}
                sx={{
                    ...formStyle.btnstyle, '& .MuiButtonBase-root': {
                      backgroundColor: '#Cancel',
                      color:'black',
                      
                      marginTop:5,
                    },
                  }} 
                  style={{ backgroundColor: '#F1F5F9', color:'black'
                  ,marginTop: 15}}
              >
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export default EditProfileDrawer;

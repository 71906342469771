import React from "react";

// Customizable Area Start
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
  Badge,
  Box
} from "@mui/material";
import { imgLogo, imgLocation, imgSearch, imgCart, imgNotification } from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderConditionalComponent = (condition: boolean, ComponentIfTrue: JSX.Element, ComponentIfFalse: JSX.Element): JSX.Element => {
    return condition ? ComponentIfTrue : ComponentIfFalse;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { userAttributes } = this.state;
    return (
      <React.Fragment>
        <AppBar position="static" sx={styles.appBarStyles}>
          <Toolbar>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                  <Grid item xs={2} display={"flex"}>
                    <Box
                      width={"56px"}
                      height={"62.25px"}
                      component={"img"}
                      src={imgLogo.default}
                      alt="Logo"
                    ></Box>
                  </Grid>
                  <Grid item xs={9} display={"flex"} alignItems={"center"}>
                    <Grid container spacing={4} alignItems={"center"}>
                      <Grid item xs={2}>
                        <IconButton color="inherit" sx={styles.iconButton}>
                          <Box
                            component={"img"}
                            src={imgLocation.default}
                            height={"24px"}
                            width={"24px"}
                            alt="Location"
                          ></Box>
                          <Typography sx={styles.stateText}>
                            {userAttributes.state}
                          </Typography>
                        </IconButton>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          variant="outlined"
                          placeholder="Search"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  width={"24px"}
                                  height={"24px"}
                                  component={"img"}
                                  sx={{ color: "#94A3B8" }}
                                  src={imgSearch.default}
                                  alt="Search"
                                ></Box>
                              </InputAdornment>
                            ),
                            sx: {
                              borderRadius: "50px",
                              paddingLeft: "10px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#CBD5E1",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#CBD5E1",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#CBD5E1",
                                },
                            },
                          }}
                          sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: "50px",
                            marginLeft: "8px",
                          }}
                          inputProps={{
                            sx: {
                              padding: "11px",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton color="inherit" sx={styles.iconButton}>
                  {this.renderConditionalComponent(
                    this.state.isBuyer,
                    <Badge badgeContent={this.props.cartCount} color="error">
                      <Box
                        width={"44px"}
                        height={"44px"}
                        component={"img"}
                        src={imgCart.default}
                        alt="Search"
                        data-test-id="cart-button"
                        onClick={() => this.handleNavigationToShoppingCart()}
                      ></Box>
                    </Badge>,
                    <React.Fragment></React.Fragment>
                  )}
                </IconButton>
                <IconButton color="inherit" sx={styles.iconButton}>
                  <Box
                    width={"44px"}
                    height={"44px"}
                    component={"img"}
                    src={imgNotification.default}
                    alt="Search"
                  ></Box>
                </IconButton>
                <Box marginLeft={"26px"} display={"flex"} flexDirection={"row"}>
                  <Avatar
                    src={
                      userAttributes.photo_url ? userAttributes.photo_url : ""
                    }
                    alt="User Avatar"
                    sx={{ width: 44, height: 44, borderRadius: "50%" }}
                  />
                  <Box
                    marginLeft={"13px"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Typography sx={styles.userNameText}>
                      {userAttributes.contact_name}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box sx={styles.secondBox}>
          <Typography
            sx={styles.profileText}
            data-test-id="profile-navigation"
            onClick={() => this.handleNavigationToProfile()}
          >
            {configJSON.profile}
          </Typography>
          <Typography
            sx={styles.productText}
            data-test-id="product-navigation"
            onClick={() => this.handleNavigationToProduct()}
          >
            Product
          </Typography>
        </Box>
      </React.Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  profileText:{
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily:"Inter",
    marginRight:"45px",
    cursor:"pointer"
  },
  productText:{
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily:"Inter",
    marginLeft:"45px",
    cursor:"pointer"
  },
  secondBox:{
    backgroundColor:"#1E293B",
    padding:"5px 0px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  switchAccountText:{
    color: "#2563EB",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily:"Inter"
  },
  userNameText:{
    color: "#334155",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily:"Inter"
  },
  stateText: {
    color: "#0F172A",
    marginLeft: "8px",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily:"Inter"
  },
  iconButton: {
    padding:"0px",
    ":hover": {
      backgroundColor: "transparent",
    },
  },
  appBarStyles: {
    padding: "7px",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
};
// Customizable Area End

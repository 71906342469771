import React from "react";
// Customizable Area Start
import { AppHeader } from "../../../components/src/AppHeader.web";
import {
  Container,
  Box,
  Typography,
  Button,
  Modal,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Divider
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { prdImg, bag, imgMailSent } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderConditionalComponent = (condition: boolean, ComponentIfTrue: JSX.Element, ComponentIfFalse: JSX.Element): JSX.Element => {
    return condition ? ComponentIfTrue : ComponentIfFalse;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { cartItemList, loading, isProccedToBuyButtonClicked, isConfirmOrderClicked } = this.state;
    return (
      <div>
        <Loader loading={this.state.loading}/>
        <AppHeader navigtion={this.props.navigation} />
        <Container maxWidth="lg">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '40px',
                  marginBottom: '54px',
                }}
              >
                <Typography sx={styles.myCartText}>
                  My Cart ({ cartItemList.length })
                </Typography>
                <Typography sx={styles.myCartPriceText}>
                  Cart value: $ {cartItemList.reduce((total, item) => total + item.price, 0).toFixed(2)} USD
                </Typography>
              </Box>

              {this.renderConditionalComponent(
                cartItemList.length > 0,
                <React.Fragment>
                  {cartItemList.map((cart,index) => {
                    const quantityArray = Array.from({ length: cart.available_quantity }, (_, i) => i + 1);
                    return(
                    <Box key={index}>
                      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={"14px 0px"}>
                        <Box data-test-id="navigation" display={'flex'} alignItems={'center'} width={"25%"} sx={{ cursor: "pointer"}} onClick={() => this.handleNavigation(cart.product_id)}>
                          <Box
                            component="img"
                            src={cart.image_url || prdImg}
                            sx={{
                              width: 63,
                              height: 50,
                              borderRadius: 2,
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                          />
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginLeft={3}
                          >
                            <Typography sx={styles.cartTitleText}>
                              {cart.title}
                            </Typography>
                            <Typography sx={styles.cartSubTitleText}>
                              {`(${cart.sub_title})`}
                            </Typography>
                            {this.renderConditionalComponent(
                              cart.available_quantity <= 5,
                              <React.Fragment>
                                <Typography sx={styles.cartQuantityText}>
                                  Only {cart.quantity} Qty Left
                                </Typography>
                              </React.Fragment>,
                              <React.Fragment>
                              </React.Fragment>
                            )}
                          </Box>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography sx={styles.qtyText}>
                            Qty:
                          </Typography>
                          <FormControl variant="outlined" size="small">
                            <Select
                              value={cart.quantity}
                              data-test-id="cart-quantity-select"
                              displayEmpty
                              onChange={(e) => this.handleQuantityChange(cart.id,cart.product_id, Number(e.target.value))}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: 60,
                                padding: "4px 8px",
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#CBD5E1',
                                  borderRadius: '8px'
                                },
                                '& .MuiSelect-select': {
                                  display: 'flex !important',
                                  alignItems: 'center !important',
                                  paddingTop: '10px !important',
                                  paddingBottom: '10px !important',
                                  paddingLeft: "8px !important",
                                  paddingRight: "45px !important",
                                },
                                '& .MuiSvgIcon-root': {
                                  color: '#0F172A',
                                  right: "8px",
                                },
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              {quantityArray.map((value) => (
                                <MenuItem key={value} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={"25%"}>
                          <Typography sx={styles.cartItemPriceText}>
                            $ {cart.price.toFixed(2)} USD
                          </Typography>
                        </Box>
                        <Box marginRight={"40px"}>
                        <IconButton
                          data-test-id="delete-order-button"
                          onClick={()=> this.deleteOrderItem(cart.id)}
                          sx={{
                            backgroundColor: "#F1F5F9",
                            color: "#0F172A",
                            borderRadius: "8px",
                            width: "32px",
                            height: "32px",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#F1F5F9",
                            },
                          }}
                        >
                           <CloseIcon />
                        </IconButton>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>)
                  })}
                </React.Fragment>,
                <React.Fragment>
                  {this.renderConditionalComponent(
                    !loading,
                    <React.Fragment>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <img src={bag} style={{width:'50%', height:'65%'}} data-test-id={"imageLoad"}/>
                      </Box>
                    </React.Fragment>,
                    <React.Fragment></React.Fragment>
                  )}
                </React.Fragment>
              )}
              {this.state.cartItemList.length > 0 && <Box
                sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginTop: '50px' }}
              >
                <div style={{ display: 'flex' }}>
                  <Button
                  data-test-id='navbtn'
                    sx={{
                      width: '197px',
                      height: '56px',
                      borderRadius: '8px',
                      backgroundColor: '#E5E5E5',
                      textTransform: 'none',
                      color: 'black',
                      fontWeight: '700',
                      fontSize: '16px',
                      fontFamily: "Inter",
                      '&:hover': { backgroundColor: '#E5E5E5', color: 'black' },
                    }}
                    onClick={() => this.navigateToAddMoreItems()}
                  >
                    Add more items
                  </Button>
                  <Button
                    data-test-id='proceed-buy-items-button'
                    sx={{
                      width: '197px',
                      height: '56px',
                      borderRadius: '8px',
                      backgroundColor: '#067B3E',
                      textTransform: 'none',
                      color: '#fff',
                      marginLeft: '15px',
                      fontWeight: '700',
                      fontSize: '16px',
                      fontFamily: "Inter",
                      '&:hover': { backgroundColor: '#067B3E', color: '#fff' },
                    }}
                    onClick={() => this.handleClickProccedToBuyItems()}
                  >
                    Proceed to buy items
                  </Button>
                </div>
              </Box>}
              <Modal 
                open={isProccedToBuyButtonClicked}  
                onClose={this.handleCloseModal}       
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyles.modalMainStyle}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={"24px 16px 24px 40px"}>
                    <Typography sx={modalStyles.modalHeaderText}>
                      Proceed to Payment 
                    </Typography>
                    <CloseIcon data-test-id="procced-more-close" sx={modalStyles.closeIcon} onClick={() => this.handleCloseModal()}/>
                  </Box>
                  <Divider/>
                  <Box padding={"0px 40px"} margin={"24px 0px"}   
                   height={this.state.cartItemList.length > 2 ? "208px" : "auto"}
                   overflow={this.state.cartItemList.length > 2 ? "scroll" : "visible"}>
                    {cartItemList.map((cart,index) => {
                      return(
                        <React.Fragment key={index}>
                          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={"24px 0px"}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Box
                                component="img"
                            src={cart.image_url || prdImg}
                            sx={{
                              width: 44,
                              height: 44,
                              borderRadius: "8px",
                              objectFit: "cover",
                              cursor: "pointer",
                                }}
                              />
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                marginLeft={"12px"}
                              >
                                <Typography sx={styles.modalTitleText}>
                                  {cart.title}
                                </Typography>
                                <Typography sx={styles.modalSubtitleText}>
                                  {cart.sub_title}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography sx={styles.modalPriceText}>
                              $ {cart.price.toFixed(2)}
                            </Typography>
                          </Box>
                          <Divider/>
                        </React.Fragment>
                      )
                    })}
                  </Box>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={"0px 40px"} marginTop={"24px"} marginBottom={"24px"}>
                      <Typography sx={modalStyles.totalValueText}>
                        Total Amount
                      </Typography>
                      <Typography sx={styles.modalPriceText}>
                          $ {cartItemList.reduce((total, item) => total + item.price, 0).toFixed(2)}
                      </Typography>
                  </Box>
                  <Box padding={"24px"} marginBottom={"30px"}>
                      <Button
                        data-test-id='confirm-orders-button'
                        fullWidth
                        sx={{
                          cursor: "pointer",
                          borderRadius: '8px',
                          backgroundColor: '#067B3E',
                          textTransform: 'none',
                          color: 'white',
                          padding:"10px 0px",
                          fontWeight: '700',
                          fontSize: '16px',
                          fontFamily: "Inter",
                          '&:hover': { backgroundColor: '#067B3E', color: 'white' },
                        }}
                        onClick={() => this.handleClickConfirmOrders()}
                      >
                        Confirm orders
                      </Button>
                  </Box>
                </Box>
              </Modal>
              <Modal 
                open={isConfirmOrderClicked}  
                onClose={this.handleCloseConfirmOrderModal}       
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyles.modalMainStyle}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={"24px 16px 24px 40px"}>
                    <Typography sx={modalStyles.modalHeaderText}>
                      Payment link
                    </Typography>
                    <CloseIcon data-test-id="confirm-order-close" sx={modalStyles.closeIcon} onClick={() => this.handleCloseConfirmOrderModal()}/>
                  </Box>
                  <Divider/>
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} margin={"24px 0px"}>
                    <Box
                      component="img"
                      src={imgMailSent}
                      sx={{
                        width: 178,
                        height: 178,
                        objectFit: "cover",
                        cursor: "pointer",
                        }}
                    />
                  </Box>
                  <Box marginBottom={"24px"} padding={"0px 53px"}>
                    <Typography sx={modalStyles.description}>
                      The invoice for these items has been sent to the email that is registered to your account.Please coordinate with the Seller in order to complete payment and delivery
                    </Typography>
                  </Box>
                  <Box padding={"24px"}>
                      <Button
                        data-test-id='shop-more-button'
                        fullWidth
                        sx={{
                          cursor: "pointer",
                          borderRadius: '8px',
                          backgroundColor: '#067B3E',
                          textTransform: 'none',
                          color: 'white',
                          padding:"10px 0px",
                          fontWeight: '700',
                          fontSize: '16px',
                          fontFamily: "Inter",
                          '&:hover': { backgroundColor: '#067B3E', color: 'white' },
                        }}
                        onClick={() => this.navigateToAddMoreItems()}
                      >
                        Shop more
                      </Button>
                  </Box>
                </Box>
              </Modal>
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyles = {
  description:{
    color: "#64748B",
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Inter",
  },
  totalValueText:{
    color: "#64748B",
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Inter",
  },
  closeIcon:{
    cursor:"pointer",
    color:"#334155",
  },
  modalHeaderText:{
    fontSize: 24,
    fontWeight: 700,
    fontFamily: "Inter",
    color: "black",
  },
  modalMainStyle:{
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "560px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
};

const styles = {
  myCartText: {
    fontSize: 20,
    fontWeight: 700,
    margin: 0,
    padding: 0,
    fontFamily: "Inter",
    color: "#1E293B"
  },
  myCartPriceText: {
    fontSize: 18,
    fontWeight: 700,
    margin: 0,
    padding: 0,
    fontFamily: "Inter",
    color: "#1E293B",
  },
  modalTitleText: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: "Inter",
    color: "black",
  },
  cartTitleText: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#0F172A"
  },
  modalSubtitleText: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#64748B",
    marginTop: "3px",
  },
  cartSubTitleText: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#0F172A",
    marginTop: "5px",
  },
  cartQuantityText: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#EF4444",
    marginTop: "5px",
  },
  cartQuantityTextNoVisibility: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#EF4444",
    marginTop: "5px",
    visibility: "hidden",
  },
  qtyText: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#64748B",
    marginRight: "8px",
  },
  modalPriceText: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Inter",
    color: "black",
  },
  cartItemPriceText: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#334155",
  },
}
// Customizable Area End

//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    productList: any,
    reviewsList: any,
    productDetail: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerProfileDescriptionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    productDetailsApiCallId: string = '';
    rateDetailsApiCallId: any = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            productList: [],
            reviewsList: [],
            productDetail: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        let id = new URLSearchParams(window.location.search).get('navigationBarTitleText');
        /*istanbul ignore next*/
        await this.verifySellerProfile();
        /*istanbul ignore next*/
        await this.verifyrateAndComp(id);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            this.getResponse(responseJson, apiRequestCallId)
        }

        // Customizable Area End
    }

    // Customizable Area Start
    getResponse = (responseJson: any, apiRequestCallId: any) => {
        /*istanbul ignore next*/
        if (responseJson) {
            if (apiRequestCallId && this.productDetailsApiCallId) {
                if (responseJson?.order_items) {
                    this.setState({ productList: responseJson?.order_items })
                }
            }
            if (apiRequestCallId && this.rateDetailsApiCallId) {
                if (responseJson?.data?.attributes) {
                    this.setState({ reviewsList: responseJson?.data?.attributes?.comments_and_ratings, productDetail: responseJson?.data?.attributes })
                }
            }
        }
    }
    handleChange = (e: SelectChangeEvent) => {
        this.setState({ sort: e.target.value })
    }
    handleBackButton = () => {
        this.props.navigation.navigate("ProductDiscription");
    }

    handleViewProductButton = () => {
        this.props.navigation.navigate("Filteritems");
    }

    async verifySellerProfile() {
        const token = await getStorageData('authToken');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.productDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.sellerOrdersAPIEndPoint}`
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async verifyrateAndComp(id) {
        const token = await getStorageData('authToken');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.rateDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ratingAndComponentAPIEndPoint}/${id}`
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}